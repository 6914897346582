//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

:root {
  --primary-light: #d8f0fb;
  --primary-mid: #7dccf4;
  --primary-mid-dark: #49ace3;
  --primary-dark: #12549a;
  --primary-deep: #00113e;
  --accent: #ffd001;
  --accent-light: #fff1b3;
}


.menu-item {
  .menu-item-img {
    opacity: 0.5;
  }
  .menu-item-svg {
    color: #ffaa3b !important;
    opacity: 0.5;
  }

  .menu-link.active {
    .menu-item-img, .menu-item-svg {
      opacity: 1;
    }
  }

  &:hover {
    .menu-item-img, .menu-item-svg {
      opacity: 1;
    }
  }
}

.contract-page-date-content{
  margin: 0;
  align-self: flex-start; 
}


.contract-page-date-content >p{
  font-weight: bolder;
}

.contract-page-company-info{
  margin: 0;
  /* align-self: flex-start;  */
}

.contract-page-company-info > img{
  height : 80px;
}

.contract-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  padding: 1rem;
  box-sizing: border-box;
  background-color: var(--primary-mid);
}

.contract-page-header img {
  height: 100%;
  box-sizing: border-box;
}

.contract-page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #fff;
}

.slogan {
  color: black;
  font-family: sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
}

.slogan.small {
  margin: 1.75rem 0.5rem;
  font-size: 1.3rem;
  line-height: 1em;
  opacity: 1;
}

.page-content {
  min-height: calc(100vh - 8rem);
  width: calc(100% - 2rem);
  margin: 0 1rem;
  padding: 0.3rem;
  padding-top: 6rem;
  border-radius: 1rem;
  box-sizing: border-box;
  background-color: white;
  font-family: sans-serif;
}

.my-page-content {
  width: calc(100% - 2rem);
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  // background-color: #fff;
  text-align: center;
}

.terms-content p, li {
  font-size: 0.9rem;
}

.terms-content ol {
  padding: 0;
  padding-left: 1rem;
}

.terms-content ol li {
  text-align: left;
}

.terms-content ol li p {
  margin: 0.25rem;
}

.terms-content ol li ul {
  margin: 1.25rem 0;
  list-style-type: disc;
}

.privacy-content p, li {
  font-size: 0.9rem;
}

.privacy-content ol {
  padding: 0;
  padding-left: 1rem;
}

.privacy-content ol li {
  text-align: left;
}

.privacy-content ol li p {
  margin: 0.25rem;
}

.privacy-content ol li ul {
  margin: 1.25rem 0;
  list-style-type: disc;
}

.legal-title {
  margin-top: 0;
  margin-bottom: 0;
  color: #00113e;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.legal-text-formatted {
  display: block;
  padding: 0 1rem;
  text-align: left;
  color: #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5em;
  white-space: pre-wrap;
  // text-align: center;

}

.contract-page-content {
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 10px;
  border: 5px solid var(--primary-mid);
  font-family: sans-serif;
}

.invoice-header{
  color: #0b2468 !important;
}
.contract-page >p {
  font-size: 2rem !important;
}

.contract-page-content >h4{
  margin-bottom: 10px;
}
.contract-page-title {
  padding: 0.5rem 3.5rem;
  color: var(--primary-dark);
  text-align: center;
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: 600;
}

.dynamic-select {
  width: auto !important;
  max-width: 100% !important;
}

.custom-button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.custom-button:focus {
  outline: none;
}

.nowrap{
  white-space: nowrap;
}

.menu-category {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.active {
    > * {
      color: $primary;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 1.9rem;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: $primary;
      transition: $transition-link;
    }
  }
}


.category-icon {
  // width: 32px;
  width: 20px;
  filter: brightness(10);
}

.progress-marker {
  position: relative;
  z-index: 9;
}

.progress-marker-name {
  position: absolute;
  top: -2rem;
  white-space: pre;
}

.progress-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: $primary;
  background-color: $primary-light;

  &.current {
    color: #fff;
    background-color: $primary;
  }

  &.complete {
    color: $success;
    background-color: $success-light;
    i { color: $success; }
  }
}

.progress-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed $gray-300;
}


.feedback-star {
  padding: 0.25rem;
  height: 24px;
  filter: grayscale(100%);
  opacity: 0.5;
  user-select: none;
}

.feedback-star.filled {
  opacity: 1;
  filter: unset;
}


.select-disabled {
  pointer-events: none;
  opacity: 0.5;
}

// invoice
.invoice{
  // width:970px !important;
  width: 100%;
  margin:40px auto;
  .invoice-header{
    padding:25px 25px 15px;
    h4{
      margin:0
    }
    .media{
      .media-body{
        font-size:.9em;
        margin:0;
      }
    }
  }
  .invoice-body{
    border-radius:10px;
    padding:25px;
    background:#FFF;
  }
  .invoice-footer{
    padding:15px;
    font-size:0.9em;
    text-align:center;
    color:#999;
  }
}

.dl-horizontal{
  margin:0;
  dt{
        float: left;
    width: 80px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  dd{
    margin-left:90px;
  }
}
.rowamount{
  padding-top:15px !important;
}
.rowtotal{
  font-size:1.3em;
}
.colfix{
  width:12%;
}
.mono{
  font-family:monospace;
}
.cmtable{width: 100%;}
.cm_th{
  width: 50%;
}
// .cm_th h4{
//   font-weight: 500;
//   font-size: 16px;
// }